/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
import React, { useState, useEffect } from 'react';
import { Card, CardContent, Accordion ,AccordionSummary, AccordionDetails,Typography } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CardHeader from '@mui/material/CardHeader';
import ProfitLineChart from '../../Charts/ProfitLineChart';
import Icon from '../../images/icon-01.svg';
import { hexToRGB } from '../../utils/Utils';
import DashboardConfig from './DashboardConfig';
import CustomLegend from './CustomLegend';
import { getHouseDetails } from '../../Services/dataService';
import { generateChartData } from '../../utils/calculations/hhProfitCalculator';
import logger from '../../utils/logger';
import {useSearchParams } from 'react-router-dom';
import { getAnalyticsInfo } from '../../Services/analyticsService';
import { useSelector,useDispatch} from 'react-redux';
import { housedetails ,setHouseLoading} from '../../features/house/houseSlice';
import ReactMarkdown from 'react-markdown';
import explanationMd from './Value_growth_explanation.md';

const convertDatatoEquityChartData = (data) => {
    return {
        labels: data.years,
        datasets: [
            {
                stack: 'House Equity',
                data: data.house_equity,
                backgroundColor: `rgba(${hexToRGB('#22c55e')}, 0.08)`,
                borderColor: '#22c55e',
                borderWidth: 2,
                tension: 0,
                pointRadius: 0,
                pointHoverRadius: 3,
                pointBackgroundColor: '#22c55e',
                clip: 20,
                label: 'House Value Growth',
            },
            {
                stack: 'Investment Equity',
                data: data.investment_equity,
                borderColor: '#a5b4fc',
                borderWidth: 2,
                tension: 0,
                pointRadius: 0,
                pointHoverRadius: 3,
                pointBackgroundColor: '#a5b4fc',
                clip: 20,
                label: 'Rent + Investment Growth',
            },
        ],
    };
};

function ValueGrowth({ address, width, height, singleAddress, mobileView, type }) {
    const [chartData, setChartData] = useState({ labels: [], datasets: [] });
    const [explanation, setExplanation] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        // Load markdown content
        fetch(explanationMd)
            .then(response => response.text())
            .then(text => setExplanation(text));
    }, []);

    const setAnalyticsData = (houseDetail) => {
        const analytics_data = houseDetail.analytics_data
            ? houseDetail.analytics_data
            : generateChartData(houseDetail, 20);
            dispatch(setHouseLoading(false));
            setChartData(convertDatatoEquityChartData(analytics_data));
        logger.debug(chartData);
    }
    const [searchParams] = useSearchParams();
    const houseData = useSelector((state) => state.house.houseDetail);


    const handleSubmitconfig = async () => {
        console.log('Button clicked!');
        
        if (houseData && Object.keys(houseData).length > 0) {
            const street_address = searchParams.get('address');
            if (street_address) {
                try {
                    const houseDetail = await getAnalyticsInfo(street_address, houseData);
                    setAnalyticsData(houseDetail);
                } catch (error) {
                    console.error('Error fetching analytics info:', error);
                }
            } else {
                console.warn('No address found in searchParams');
            }
        } else {
            console.warn('houseData is either undefined or empty');
        }
    };
    
    useEffect(() => {
        if(houseData.address !== address.street_address ){
        getHouseDetails(address.street_address, address.google_place_id).then((houseDetail) => {
            var fullHouseData={};
            setAnalyticsData(houseDetail);
            if (houseDetail && Object.keys(houseDetail).length > 0) {
                 fullHouseData = {
                    house_price: houseDetail.house_price ,
                    down_payment_percent: houseDetail.down_payment_percent||0,
                    interest_rate: houseDetail.interest_rate ,
                    initial_rent: houseDetail.initial_rent ,
                    bathrooms: houseDetail.bathrooms || 3 ,
                    bedrooms: houseDetail.bathrooms || 3,
                    loan_term : houseDetail.loan_term || 15,
                    hold_for_years: houseDetail.hold_for_years||20,
                    HOA: houseDetail.HOA || 0 ,
                    insurance_cost:houseDetail.insurance_cost|| '',
                    address:address.street_address,
                    google_place_id:address.google_place_id,
                    property_tax_rate: houseDetail.property_tax_rate || '',
                    buying_closing_costs: houseDetail.buying_closing_costs || 2.5,
                    condo_fees: houseDetail.HOA || 0,
                    additional_monthly_costs: houseDetail.additional_monthly_costs || '',
                    house_price_appreciation: houseDetail.house_price_appreciation || 4,
                    initial_rent: houseDetail.initial_rent || '',
                    monthly_renters_insurance: houseDetail.monthly_renters_insurance || '',
                    rent_deposit: houseDetail.rent_deposit || '',
                    brokers_fees: houseDetail.brokers_fees || '',
                    rent_appreciation: houseDetail.rent_appreciation || '',
                    buyerStatus: houseDetail.buyerStatus || 1,
                    analytics_data: houseDetail.analytics_data || {}
                };
            }
                dispatch(housedetails(fullHouseData));
        });
    }else{
        setAnalyticsData(houseData);
    }
    }, [address]);

    return (
        <Card>
            {/* <CardHeader
                avatar={<img src={Icon} width="32" height="32" alt="Icon 01" />}
                title="Value Growth"
                subheader="Growth of value across investing horizon"
            /> */}
            <CardContent>
                <ProfitLineChart data={chartData} width={width} height={height} singleAddress={singleAddress} mobileView={mobileView} type={type} />
                <div style={{ fontSize: '12px', color: 'gray', textAlign: 'center', marginTop: '10px' }}>Not a financial or tax advice. Please contact your advisor as needed. Meant for informational purposes only.</div>

                <Accordion defaultExpanded className="accordion-margin">
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon className="accordion-icon-red" />}
                        aria-controls="Configure To Personalize"
                        id="configure"
                    >
                        <Typography variant="h6" component="h6">Configure To Personalize</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="accordion-details">
                        <DashboardConfig setAnalyticsData={setAnalyticsData} />
                    </AccordionDetails>

                    <div className="accordion-button-container">
                        <button className="accordion-button" onClick={handleSubmitconfig}>Submit</button>
                    </div>
                </Accordion>

                <Accordion className="accordion-margin">
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon className="accordion-icon-red" />}
                        aria-controls="explanation"
                        id="explnation"
                    >
                        <Typography  variant="h6" component="h6">User Guide for Byvr Investment Analytics Tool</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ReactMarkdown>{explanation}</ReactMarkdown>
                    </AccordionDetails>
                </Accordion>
            </CardContent>

        </Card>
    );
}

export default ValueGrowth;
