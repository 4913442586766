import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Chart, LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip } from 'chart.js';
import 'chartjs-adapter-moment';
import { getHovervalue } from '../utils/Utils';

Chart.register(
  LineController,
  LineElement,
  Filler,
  PointElement,
  LinearScale,
  TimeScale,
  Tooltip,
);

const customSubtitlePlugin = {
  id: 'customSubtitle',
  beforeDraw(chart) {
    const { ctx, chartArea, options } = chart;

    const fontSize = options.plugins.customSubtitle.font.size || 14;
    const fontWeight = options.plugins.customSubtitle.font.weight || 'normal';
    const fontColor = options.plugins.customSubtitle.color || '#000';
    const backgroundColor = options.plugins.customSubtitle.backgroundColor || '#E0F7FA';
    const padding = options.plugins.customSubtitle.padding || { top: 0, bottom: 200, left: 15, right: 15 };
    const borderRadius = options.plugins.customSubtitle.borderRadius || 10;

    const textHeight = fontSize + 4;
    const boxWidth = textWidth + padding.left + padding.right;
    const boxHeight = textHeight + padding.top + padding.bottom;

    const x = chartArea.right - boxWidth - 10;
    const y = chartArea.top - boxHeight - 30;

    ctx.save();

    ctx.fillStyle = backgroundColor;
    ctx.beginPath();
    ctx.moveTo(x + borderRadius, y);
    ctx.lineTo(x + boxWidth - borderRadius, y);
    ctx.quadraticCurveTo(x + boxWidth, y, x + boxWidth, y + borderRadius);
    ctx.lineTo(x + boxWidth, y + boxHeight - borderRadius);
    ctx.quadraticCurveTo(x + boxWidth, y + boxHeight, x + boxWidth - borderRadius, y + boxHeight);
    ctx.lineTo(x + borderRadius, y + boxHeight);
    ctx.quadraticCurveTo(x, y + boxHeight, x, y + boxHeight - borderRadius);
    ctx.lineTo(x, y + borderRadius);
    ctx.quadraticCurveTo(x, y, x + borderRadius, y);
    ctx.closePath();
    ctx.fill();

    ctx.fillStyle = fontColor;
    ctx.font = `${fontWeight} ${fontSize}px Arial`;
    ctx.textBaseline = 'middle';
    ctx.textAlign = 'center';
    ctx.fillText(subtitleText, x + boxWidth / 2, y + boxHeight / 2);

    ctx.restore();
  },
};

Chart.register(customSubtitlePlugin);

function CompareProfitLineChart({ data, width, height, singleAddress, mobileView }) {
  const houseDetail = useSelector((state) => state.house.houseDetail);

  const canvasRef = useRef(null);
  const chartRef = useRef(null);
  const loadings = useSelector((state) => state.house.houseload);
  const [loading, setLoading] = useState(false);
  const colors = [
    { background: 'rgba(255, 99, 132, 0.2)', border: 'rgba(255, 99, 132, 1)' },   
    { background: 'rgba(54, 162, 235, 0.2)', border: 'rgba(54, 162, 235, 1)' },  
    { background: 'rgba(75, 192, 192, 0.2)', border: 'rgba(75, 192, 192, 1)' },  
    { background: 'rgba(255, 206, 86, 0.2)', border: 'rgba(255, 206, 86, 1)' },  
    { background: 'rgba(153, 102, 255, 0.2)', border: 'rgba(153, 102, 255, 1)' }, 
    { background: 'rgba(255, 159, 64, 0.2)', border: 'rgba(255, 159, 64, 1)' },   
    { background: 'rgba(199, 199, 199, 0.2)', border: 'rgba(199, 199, 199, 1)' }, 
    { background: 'rgba(83, 102, 255, 0.2)', border: 'rgba(83, 102, 255, 1)' },   
    { background: 'rgba(255, 99, 255, 0.2)', border: 'rgba(255, 99, 255, 1)' }, 
    { background: 'rgba(100, 255, 100, 0.2)', border: 'rgba(100, 255, 100, 1)' }   
];

  useEffect(() => {
    setLoading(loadings);
  }, [loadings]);

    useEffect(() => {
        const canvas = canvasRef.current;

        if (canvas) {
            const ctx = canvas.getContext('2d');

            if (chartRef.current) {
                chartRef.current.destroy();
            }

            chartRef.current = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: data.labels,
                    datasets: data.datasets.map((dataset, index) => {
                        const color = colors[index % colors.length]; 
                        return {
                            ...dataset,
                            backgroundColor: color.background,
                            borderColor: color.border,
                            fill: false,
                            tension: 0.4, 
                            pointRadius: 2, 
                            pointBackgroundColor: color.border,
                        };
                    }),
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    layout: {
                        padding: 5,
                    },
                    plugins: {
                        title: {
                            display: true,
                            text: `Comparative Profit Growth`,
                        },
                        tooltip: {
                            enabled: true,
                            mode: 'index',
                            intersect: false,
                            callbacks: {
                                label: (context) => {
                                    const value = context.raw;
                                    return `$${parseInt(value / 1000)} K`;
                                },
                            },
                            backgroundColor: 'rgba(0, 0, 0, 0.7)', // Darker tooltip background
                            titleColor: '#ffffff', // White title color
                            bodyColor: '#ffffff', // White body color
                        },
                        legend: {
                            display: true,
                            labels: {
                                color: '#4a4a4a', // Dark gray legend labels
                            },
                        },
                    },
                    scales: {
                        x: {
                            type: 'linear',
                            display: true,
                            title: {
                                display: true,
                                text: 'Date',
                            },
                            grid: {
                                color: 'rgba(0, 0, 0, 0.1)', // Light gray grid lines
                                borderDash: [5, 5], // Dashed grid lines
                            },
                            ticks: {
                                callback: function(value) {
                                    return value; 
                                },
                                color: '#4a4a4a',
                            },
                        },
                        y: {
                            type: 'linear',
                            display: true,
                            title: {
                                display: true,
                                text: 'Equity',
                            },
                            ticks: {
                                color: '#4a4a4a', // Dark gray ticks
                                callback: function (value) {
                                    if (value === 0) return '$0.00 M';
                                    return '$' + (value / 1000000).toFixed(2) + ' M';
                                },
                            },
                            grid: {
                                color: 'rgba(0, 0, 0, 0.1)', // Light gray grid lines
                                borderDash: [5, 5], // Dashed grid lines
                            },
                            suggestedMin: Math.min(...data.datasets.flatMap(dataset => dataset.data)), 
                            suggestedMax: Math.max(...data.datasets.flatMap(dataset => dataset.data)), 
                            beginAtZero: false, 
                            min: Math.min(...data.datasets.flatMap(dataset => dataset.data)) - 10, 
                            max: Math.max(...data.datasets.flatMap(dataset => dataset.data)) + 10,
                        },
                    },
                    interaction: {
                        mode: 'index',
                        intersect: false,
                    },
                },
            });
        }

        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
            }
        };
    }, [data]);


    return (
        <div
          style={{
            height: singleAddress || mobileView ? '60vh' : '',
            position: 'relative',
          }}
        >
          {loading && <div className="loader"></div>}
          <canvas
            ref={canvasRef}
            width={width}
            height={height}
            className={loading ? 'blur' : ''}
          />
        </div>
      );
}

export default CompareProfitLineChart;
