/* eslint-disable react/jsx-indent-props */
/* eslint-disable max-len */
import React, { useState } from 'react';
import {
    Typography, Select, Box, Tabs, Tab,
    AccordionSummary, AccordionDetails,
} from '@mui/material';

import { useSelector } from 'react-redux';
import FinancialSetting from './ByvrFSConfigview';
import RealStateSetting from './ByvrRSConfigview';
import UserStatusSetting from './ByvrUserStatusConfigview';
import RentingSetting from './ByvrRentingConfigview';

import { Link } from 'react-router-dom';

function DashboardConfig({setAnalyticsData }) { 
    // const { isLoggedIn, login, logout } = useAuth();
    let isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
    const [activeTab, setActiveTab] = React.useState(0);
    isLoggedIn = true;
    const handleTabChange = (event, newValue) => {
        if (newValue !== undefined) setActiveTab(newValue);
    };

    return (
        <div className="blur-overlay">
            <div className={isLoggedIn ? '' : 'blurred'} >

                <Tabs value={activeTab} onChange={handleTabChange} aria-label="Configure to Personalize" className='loan-tax'>
                    <Tab label="House Variables" value={0} />
                    <Tab label="Financial Variables" value={1} />
                    <Tab label="Users Variables" value={2} />
                    <Tab label="Renting variables" value={3} />
                </Tabs>
                {activeTab === 0 && <RealStateSetting />}
                {activeTab == 1 && <FinancialSetting ></FinancialSetting>}
                {activeTab == 2 && <UserStatusSetting ></UserStatusSetting>}
                {activeTab == 3 && <RentingSetting></RentingSetting>}
            </div>
            {
                !isLoggedIn && (
                    <Link to='/login' className="overlay-message">
                        Please log-in to Personalize
                    </Link>
                )
            }
        </div >
    );
}

export default DashboardConfig;
